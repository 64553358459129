import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken} from "firebase/messaging"
import { getAnalytics } from "firebase/analytics";
import axios from "axios"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCNz_MomxcBfMZ3WBE6YAASCs2tx5OXKQs",
  authDomain: "quick-lviv.firebaseapp.com",
  projectId: "quick-lviv",
  storageBucket: "quick-lviv.appspot.com",
  messagingSenderId: "391896726916",
  appId: "1:391896726916:web:cd9ab83e4f2c27ced0b1d1",
  measurementId: "G-VNELTCJY5J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app)
// 1. Generate a new key pair

// Request Permission of Notifications
Notification.requestPermission().then(async (permission) => {
  if (permission === "granted") {
    console.log("Permission granted!!");
    
    const token = await getToken(messaging, {vapidKey: "BAq7zhYR-ETa1ofOV0RMEnWKqr3AaJcJr4J6M8XOtsI2bkqJIMPj3QsaVqdtLmLArT7wGXBvp_cWaTF_qUW5qFg"});
    console.log(token);    
    axios.post("https://quicklvivapi.aoo.cz/subscribe", {id: token}).then((res) => {
      console.log(res);
    }
    ).catch((err) => {
      console.log(err);
      alert("Error while subscribing to notifications, try again!")
    })
    
  } else {
    alert("You denied the permission to send notifications, you can change it in the browser settings.")
  }

}).catch((err) => {
  console.log('Unable to get permission to notify.', err);
  setTimeout(() => {
    window.location.reload();
  }, 1000);
});

createApp(App).mount('#app')
